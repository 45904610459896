/* global $ */

class Home
{
    constructor () 
    {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.slick-carousel').slick();
        });
    }
}

new Home;